/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('iframe').each(function(){
          var url = $(this).attr("src");
          var char = "?";
          if(url.indexOf("?") !== -1) {
            var charet = "&";
            $(this).attr("src",url+charet+"wmode=transparent");
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      $('.slider').slick({
        lazyLoad: 'ondemand',
        autoplay: true,
        dots: true,
        autoplaySpeed: 7000,
        speed: 600,
        pauseOnHover: false,
      });

        document.addEventListener("DOMContentLoaded", function(event) {
          $(window).bind("load resize", function(){
            setTimeout(function() {
              $("#fbwrap .fb-page").attr("data-width", parseInt($("#fbwrap").width()));
              FB.XFBML.parse( );
            }, 100);
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
		// $(function(){
		//   $('#masonwrap').masonry({
		//     // options
		//     itemSelector : '.entry-content-post'
		//   });
		// });

		// $(document).ready(function(){
		// });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'attachment': {
      init: function() {

        var container = document.querySelector('.gallerysidebar');
        var msnry;

        imagesLoaded( container, function() {
          msnry = new Masonry( container, {
            // options...
            columnWidth: ".grid-sizer",
            itemSelector: ".gallerysidebarthumb"
          });
        });

      }
    },
    'page_template_template_galleria': {
      init: function() {

        var container = document.querySelector('.gallerywrap');

        imagesLoaded( container, function() {
        var msnry = new Masonry( container, {
            // options...
            columnWidth: ".grid-sizer",
            itemSelector: '.gallerythumb'
          });
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* Script for asynchronus load of sharing buttons */

 (function(w, d, s) {

   function go(){
  	var js, fjs = d.getElementsByTagName(s)[0], load = function(url, id) {
  	if (d.getElementById(id)) {return;}
    	js = d.createElement(s); js.src = url; js.id = id;
    	fjs.parentNode.insertBefore(js, fjs);
  	};
        //Facebook
  	load('//connect.facebook.net/en_US/all.js#xfbml=1', 'fbjssdk');
        //Google+
  	load('https://apis.google.com/js/plusone.js', 'gplus1js');
        //Twitter
  	load('//platform.twitter.com/widgets.js', 'tweetjs');
        //LinedIN
  	// load('//platform.linkedin.com/in.js', 'lnkdjs');
        //Pinterest
  	load('//assets.pinterest.com/js/pinit.js', 'pinitjs');
    }
 	if (w.addEventListener) { w.addEventListener("load", go, false); }
  	else if (w.attachEvent) { w.attachEvent("onload",go); }

}(window, document, 'script'));
